<template>

<div id="Quiz"> 
  <Header/>
  <body class="">
    <nav class="navbar bg-white fixed-top nicole-noshadow">
      <div class="container">
        <!-- <a class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" data-bs-toggle="modal" data-bs-target="#notice3"> -->
        <a class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" @click="onBack">
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
          {{examPaperName}}
        </a>             
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" >
          <img class="avatar rounded-0 avatar-sm" :src="(isCollect) ? 'assets/img/ic_saved.png' : 'assets/img/ic_unsave.png'" @click="collectExam"> 
        </a> 
      </div>
    </nav> 
    <div class="nicolespace"></div>
    <div class="container mt-2 mt-md-3 mb-4 mb-md-5">

      <!-- 題型說明 -->
      <div class="text-left">  
        <div class="text-s text-dark" id="exam-paper-description">{{examPaperDescription}}</div>
      </div>
      <!--Exam Group 圖片-->
      <div v-show="currExam.exam_style == 'G4' || currExam.exam_style == 'G5' || currExam.exam_style == 'P1' || currExam.exam_style == 'P2' || currExam.exam_style == 'P3'">
        <div class="text-center mt-3">  
            <img v-if="config && config.old_images && config.old_images=='Y'"  v-real-img="'data:image/jpeg;base64,'+examGroupImage" src="assets/img/noback.png" class="green_test_pic rounded-3" style="object-fit: contain;">
            <img v-if="config && config.old_images && config.old_images=='N'"  v-real-img="examGroupImage" src="assets/img/noback.png" class="green_test_pic rounded-3" style="object-fit: contain;"> 
        </div>  
      </div>

      <!--Exam Group 音檔-->
      <div class="my-2" v-show="currExam.exam_style == 'G1' || currExam.exam_style == 'G2' || currExam.exam_style == 'G3' || currExam.exam_style == 'G4' || currExam.exam_style == 'G5' || currExam.exam_style == 'P1' || currExam.exam_style == 'P2' || currExam.exam_style == 'P4' || currExam.exam_style == 'P6' || currExam.exam_style == 'Q1' || currExam.exam_style == 'Q2' || currExam.exam_style == 'Q3' || currExam.exam_style == 'Q4'">
        <div class="text-center" >
          <img :src="isPlayAudio ? 'assets/img/play.png' : 'assets/img/play_nostar.png' " class="avatar avatar-l" @click="startPlayVoice">        
        </div>
      </div>

      <!--Exam Group 文字-->
      <div class="container text-left mt-3 ck-content" v-show="currExam.exam_style == 'G1' || currExam.exam_style == 'G2' || currExam.exam_style == 'G5' || currExam.exam_style == 'P1' || currExam.exam_style == 'P3' || currExam.exam_style == 'P5' || currExam.exam_style == 'P6' || currExam.exam_style == 'Q1' || currExam.exam_style == 'Q2' || currExam.exam_style == 'Q3' || currExam.exam_style == 'Q6'">
        <!-- eslint-disable vue/no-v-html -->
          <div class="text-20 text-dark text-simbold lh-xl w-100 " style="line-height:2 !important;" v-html="examGroupName" v-if="currExam.exam_list != undefined && currExam.exam_list.length > 0 && currExam.exam_list[0].exam.eetid == 'sentence'"></div>
          <div class="" style="line-height:2 !important;" v-html="examGroupName" v-if="currExam.exam_list != undefined && currExam.exam_list.length > 0 && currExam.exam_list[0].exam.eetid == 'preview'"></div>
          <!--eslint-enable-->
      </div>

      <!-- Exam -->
      <div v-if="currExam.exam_mode == 1">
        <div class="exam" :id="(currExam.exam_list.length > 0 && currExam.exam_list[0].exam != undefined && currExam.exam_list[0].exam.eid  != undefined) ? currExam.exam_list[0].exam.eid : ''" data="N">
          <!-- 選項 -->
          <div v-if="examItemStyle=='text'">
            <div class="text-center choose_mt" >
              <div class="position-relative chooise_mb" v-for="(item, index) in currExam.exam_list[0].item" :key='item.eiid'>
                <div class="badge badge-secondary rounded-3 choose-btn text-simbold chooise text-wrap text-left" 
                    @click="OnItemClick(item.eiid ,item.eid , currExam.exam_list[0].exam.etid)" 
                    :id="'text'+item.eiid">
                  <div class="d-flex"> 
                    <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="index==0">A. </span> 
                    <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="index==1">B. </span> 
                    <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="index==2">C. </span> 
                    <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="index==3">D. </span> 
                    <!-- eslint-disable vue/no-v-html --> 
                    <div class="text-mm text-simbold lh18 contentbox" v-html="item.name"></div>
                    <!--eslint-enable-->
                  </div> 
                </div>
                <img src="assets/img/img008.png" class="avatar avatar-check rounded-0 position-absolute topdowncenter ms-2 hide" :id="'ok'+item.eiid">
                <img src="assets/img/ic_test_fail.png" class="avatar avatar-check rounded-0 position-absolute topdowncenter ms-2 hide" :id="'fail'+item.eiid">
              </div>
            </div>
          </div>
          <div v-if="examItemStyle=='image'">
            <div class=" mt-3">  
              <div class="text-center picbox mx-auto mt-3 mt-md-4">   
                <div class="w-100  mb-3 mb-md-4"> 
                  <div class="nicolerow" v-for="(item) in currExam.exam_list[0].item" :key='item.eiid'> 
                    <div v-if="config && config.old_images && config.old_images=='Y'"  class="w-70 rounded-3 text-right examItemImage greentestpicl  mx-auto mb-3 pe-1" 
                        :style="item.image_url.indexOf('background-image') > -1 ? item.image_url : (item.image_url != '' ? 'background-image: url(data:image/jpeg;base64,'+item.image_url+');' : 'background-image: url(assets/img/noback.png);')" 
                        @data-url="item.eiid"
                        @click="OnItemClick(item.eiid ,item.eid , currExam.exam_list[0].exam.etid)" 
                        :id="'text'+item.eiid" >
                      <img src="assets/img/white_yes.png" class="avatar avatar-check rounded-0 hide" :id="'ok'+item.eiid"> 
                      <img src="assets/img/white_no.png" class="avatar avatar-check rounded-0 hide" :id="'fail'+item.eiid">
                    </div>
					
					 <div v-if="config && config.old_images && config.old_images=='N'" class="w-70 rounded-3 text-right examItemImage greentestpicl  mx-auto mb-3 pe-1" 
                        :style="item.image_url.indexOf('background-image') > -1 ? item.image_url : (item.image_url != '' ? 'background-image: url(data:image/jpeg;base64,'+item.image_url+');' : 'background-image: url(assets/img/noback.png);')" 
                        @data-url="item.eiid"
                        @click="OnItemClick(item.eiid ,item.eid , currExam.exam_list[0].exam.etid)" 
                        :id="'text'+item.eiid" >
                      <img src="assets/img/white_yes.png" class="avatar avatar-check rounded-0 hide" :id="'ok'+item.eiid"> 
                      <img src="assets/img/white_no.png" class="avatar avatar-check rounded-0 hide" :id="'fail'+item.eiid">
                    </div> 
                  </div>              
                </div>                           
              </div>              
            </div> 
          </div>    
          <!-- Exam Concept -->
          <div class="mt-3" v-show="isShowConcept">
            <h5 class="text-dark text-bold  mt-2 mb-3 text-mm">題目解析</h5>
            <div class="d-flex mb-2"> 
              <!-- eslint-disable vue/no-v-html -->
              <div class="text-s text-dark" v-html="(currExam.exam_list != undefined && currExam.exam_list.length > 0 && currExam.exam_list[0].concept != undefined && currExam.exam_list[0].concept.length > 0 )? currExam.exam_list[0].concept[0].name : ''"></div>
              <!--eslint-enable-->
            </div>
          </div>                    
        </div>
      </div>
      <div v-else-if="currExam.exam_mode == 2">
        <div class="exam" v-for="(item, index) in currExam.exam_list" :key="item.exam.eid" :id="(item.exam != undefined && item.exam.eid  != undefined) ? item.exam.eid : ''" data="N">
          <!-- Exam 圖片 -->
          <!-- Exam 音檔 -->
          <!-- Exam 文字 -->
          <div class="d-flex mt-3 "> 
            <span class="titlebox text-simbold text-dark text-simbold lh18">Q{{index+1}}.</span> 
            <!-- eslint-disable vue/no-v-html --> 
            <div class="text-s text-simbold text-dark contentbox lh18 ck-content" v-html="item.exam.name"></div>
            <!--eslint-enable-->
          </div> 
          <!-- 選項 -->
          <div v-if="item.exam.esid=='P4' || item.exam.esid=='P5' || item.exam.esid=='P6'">
            <div class="text-center picbox mx-auto mt-3 mt-md-4" > 
              <div v-for="(examItem, examItemIndex) in item.item" :key='examItem.eiid' v-if='examItemIndex%2==0'>
                <div class="d-flex mb-3 mb-md-4" > 
                  <div class="w-50 me-2 rounded-3 text-right me-1 me-md-3 greentestpic" v-real-exam-background-img="examItem.image_url" style="background-image: url(assets/img/anastasia.jpg); background-size: cover;"
                      @data-url="examItem.eiid"
                      @click="OnItemClick(examItem.eiid ,examItem.eid , item.exam.etid)" 
                      :id="'text'+examItem.eiid" 
                      >
                    <img src="assets/img/white_yes.png" class="avatar avatar-check rounded-0 hide" :id="'ok'+examItem.eiid"> 
                    <img src="assets/img/white_no.png" class="avatar avatar-check rounded-0 hide" :id="'fail'+examItem.eiid">
                  </div>
                  <div class="w-50 ms-2 rounded-3 text-right ms-1 ms-md-3 greentestpic" v-real-exam-background-img="item.item[examItemIndex+1].image_url" style="background-image: url(assets/img/noback.png); background-size: cover;" 
                      v-if="examItemIndex+1<item.item.length"
                      @click="OnItemClick(item.item[examItemIndex+1].eiid ,item.item[examItemIndex+1].eid , item.exam.etid)"
                      :id="'text'+item.item[examItemIndex+1].eiid">
                    <img src="assets/img/white_yes.png" class="avatar avatar-check rounded-0 hide" :id="'ok'+item.item[examItemIndex+1].eiid"> 
                    <img src="assets/img/white_no.png" class="avatar avatar-check rounded-0 hide" :id="'fail'+item.item[examItemIndex+1].eiid">
                  </div>
                </div> 
              </div> 
            </div>
          </div>
          <div v-else>
            <div class="text-center choose_mt" >
              <div class="position-relative chooise_mb" v-for="(examItem, examItemIndex) in item.item" :key='examItem.eiid'>
                <div class="badge badge-secondary rounded-3 choose-btn text-simbold chooise text-wrap text-left" 
                    @click="OnItemClick(examItem.eiid ,examItem.eid , item.exam.etid)" 
                    :id="'text'+examItem.eiid">
                  <div class="d-flex"> 
                    <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="examItemIndex==0">A. </span>
                    <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="examItemIndex==1">B. </span>
                    <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="examItemIndex==2">C. </span>
                    <span class="titlebox titlebox text-mm  text-simbold lh18 " v-if="examItemIndex==3">D. </span>
                    <!-- eslint-disable vue/no-v-html --> 
                    <div class="text-mm text-simbold lh18 contentbox" v-html="examItem.name"></div>
                    <!--eslint-enable-->
                  </div> 
                </div>
                <img src="assets/img/img008.png" class="avatar avatar-check rounded-0 position-absolute topdowncenter ms-2 hide" :id="'ok'+examItem.eiid">
                <img src="assets/img/ic_test_fail.png" class="avatar avatar-check rounded-0 position-absolute topdowncenter ms-2 hide" :id="'fail'+examItem.eiid">
              </div>
            </div>
          </div>
          <!-- 解析 -->
          <div class="container text-left mt-3 " v-show="isShowConcept">
            <h5 class="text-dark text-bold  mt-2 mb-3 text-mm">題目解析</h5>
            <div class="d-flex mb-2"> 
              <!-- eslint-disable vue/no-v-html -->
              <div class="text-s text-dark" v-html="item.concept.length > 0 ? item.concept[0].name : '' "></div>
              <!--eslint-enable-->
            </div> 
          </div>
          <div class="nicolespace"></div>
        </div>
      </div>


      <!--下一題-->
      <div class="text-center  my-5">
        <button type="button" class="btn btn-lg  btn-success rounded-pill w-85  text-s" @click="OnNextClick()" data-bs-dismiss="modal" :class="isCanNext ? '' : 'disabled'" >{{btnNextName}}</button>
      </div>

    </div>

    <div class="nicolespace"></div>  

    <!-- Modal 離開彈窗 -->
    <div class="modal fade " id="notice3" tabindex="-1" role="dialog" aria-labelledby="notice3Label" aria-hidden="true">
      <div class="modal-sm modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content nicole-modal"> 
          <div class="modal-header">
            <h4 class="text-center text-lg fw-bold text-dark mb-0">確定要離開嗎？</h4>
          </div>    
          <div class="modal-body">    
            <div class="text-center">
              <h5 class="text-simbold text-s text-dark mb-0">中途離開不會計入學習歷程喔</h5>
            </div>    
          </div>
          <div class="border-top d-flex align-content-center justify-content-between">
            <div class="w-50 text-center border-end py-3">
              <h5 class="text-success text-simbold mb-0" data-bs-dismiss="modal" @click="back()">離開</h5>
            </div>
            <div class="w-50 text-center py-3">
              <h5 class="text-success text-simbold mb-0 " data-bs-dismiss="modal">繼續答題</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 播放器 -->
    <video-player  class="video-player-box"
                 ref="videoPlayer"
                 :options="playerOptions"
                 :playsinline="true"
                 customEventName="customstatechangedeventname"

                 @play="onPlayerPlay($event)"
                 @pause="onPlayerPause($event)"
                 @ended="onPlayerEnded($event)"
                 @waiting="onPlayerWaiting($event)"
                 @playing="onPlayerPlaying($event)"
                 @loadeddata="onPlayerLoadeddata($event)"
                 @timeupdate="onPlayerTimeupdate($event)"
                 @canplay="onPlayerCanplay($event)"
                 @canplaythrough="onPlayerCanplaythrough($event)"
                 @statechanged="playerStateChanged($event)"
                 @ready="playerReadied"
                 v-show="false"
                 >
    </video-player>
  </body>
</div>
</template>

<script>
import Header from '@/components/Header.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import { config } from "@/include/config"; 


export default {
    name: 'Quiz',
    components: {
        Header,
    },
    data () {
      return { 
        // API Setting
          setDebugMode:true ,
          apiDebug: true,
          apiPostDebug: true,
          debugMode: true,
          apiName: "",
          fun:"",
          act:"",
			    config:"",
          form: {},


        // Data
          backUrl: "",
          examGroupName: "",  // 題目文字
          examGroupAudio: "", // 音檔URL
          examGroupImage: "", // 圖片URL
          examParent: [],     // 選項
          eid: "", // 當前 Exam ID
          eplid: "",
          huepid: "",

          exam: [], // 所有資料
          currExam: [], // 當前題組的資料
          mEid: "", // 選項的判斷參數
          currIndex: -1,
          
          mExamLength: 0, // 題目數量
          countExam: 0,

          examPaperType: "0", // 考卷類型
          examPaperName: "",
          examPaperDescription: "", // 考卷描述

          timer: null, // 倒計時碼表
          time: 0, // 倒計時秒數

          query: "",

          examItemStyle: "", // Vue 題目類型 / text, image


          btnNextName: "進入測驗", // 下一題的按鈕名稱
          lsid: "",
          tryError: "N",
          taskBack: "N",
          egid: "",

        // 考卷類型
          examPaperTypeWordReexc: "1",
          examPaperTypeQuiz: "2",
          examPaperTypeListening: "3",
          examPaperTypeLevel: "4",

        // exam_type：單選、複選
          SingleExamItem: 1,
          MultipleExamItem: 2,

        // Status
          debug: true,

          isCheckAnswer: false, // 是否可重複點選選項

          isShowClock: false, // 是否顯示倒計時
          isSelect: false, // 是否被選取
          
          isShowConcept: true, // 是否顯示解析
          isExampleExam: false, // 是否為範例題目
          isShowAnswer: true, // 是否顯示答案

          recordExamItem: null, // 紀錄作答
          recordEiidArray: null, // 紀錄要送出API的作答
          isCanNext: false,
          isCollect: false, // 已經被收藏

        // 已被選擇的 Exam Item ID
          selectExamItem: [], // Exam Item 的答對與答錯變化

        // 音檔
          isPlayAudio: false, // 是否正再播放音檔
          isAutoPlayAduio: false, // 是否要自動播放音檔
          audioUrlM3u8: "", // 音檔路徑
          isGetAudioToken: false,


          playerOptions: {
            // videojs options
            muted: false,
            language: 'en',
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            sources: [],
            poster: "/static/images/author.jpg",
          },

        // 查閱歷史作答紀錄
          user_reply: [],
          isShowHistory: false,
      }
    },
    methods: {
      openLogin(){
        this.$JsBridge.callHandler('openVueLoginView',JSON.stringify({backUrl:"ListeningExam", type:""}), this.openLoginCallback);
        this.backUrl = "";
      },
      openLoginCallback(){

      },
      setOnBack(){
        this.$JsBridge.callHandler('setOnback', '', this.onBack);
      },
      onBack(){    
        this.showModal('#notice3');
      },
      back() {
        this.apiName="page_quiz_exit/"+this.$GlobalData.uid;
        this.form = {};
        this.form["uid"] = this.$GlobalData.uid;
        this.form["eplid"] = this.eplid;
        this.form["huepid"] = this.huepid;
        this.form["tryError"] = this.tryError;
        this.fun = "Quiz";
        this.act = "put";
        
        oAC.putRecord(oAC.getApiParam(this), {}).then((res)=> {
          
        }).catch((err)=> {
          oltConsole.log(err);
        });

        this.putExitExamCallback();
      },
      putExitExamCallback(){
        if (this.$route.params.back != undefined && this.$route.params.back != "" ) {
          this.$router.push({
            name: this.backUrl
          });
        } 
        else {
          switch(this.examPaperType){
            case this.examPaperTypeWordReexc:
            break;
            case this.examPaperTypeListening:
              this.$router.push({
                name: "ListeningExam",
                params: this.$route.params
              });
            break;
            case this.examPaperTypeLevel:
              this.$router.push({
                name: "LevelExamIntro"
              });
            break;
            default:
              this.$router.push({
                name: "TaskList",
                params:{
                  lsid: this.lsid,
                  taskBack: this.taskBack
                }
              });

              var name = "";
              var params = {};
              if(this.backUrl && this.backUrl != "" && this.backUrl != undefined && this.backUrl.indexOf("?") > -1){
                  name = this.backUrl.substring(0, this.backUrl.indexOf("?"));
                  var queryStr = this.backUrl.substring(this.backUrl.indexOf("?") + 1, this.backUrl.length);
                  if(queryStr != ""){
                  const urlSearchParams = new URLSearchParams(queryStr);   
                      params = Object.fromEntries(urlSearchParams.entries()); 
                  }
              }
              this.$router.push(
              {
                  name : name,
                  params: params,
              });
            break;
          }
        }
      },
      getAPI(){
        this.apiName="page_quiz";
        this.form = {};
        this.form["uid"] = this.$GlobalData.uid;
        this.form["lsid"] = this.lsid;
        this.form["tryError"] = this.tryError;
        this.form["egid"] = this.egid;
        oAC.getAllRecord(oAC.getApiParam(this), this.form).then(res => {
          if(res && res.get_page_quiz && res.get_page_quiz.record != undefined && res.get_page_quiz.record.length > 0){  
            this.exam = res.get_page_quiz.record;
            this.constructorExam();
            this.InitExam();
            this.RandomExam();
            this.UpdateExam();
          } 
      
          if (res && res.get_page_quiz != undefined && res.get_page_quiz.text != undefined) {
            if (res.get_page_quiz.text.name != undefined) { this.examPaperName = res.get_page_quiz.text.name; }
            if (res.get_page_quiz.text.description != undefined) { this.examPaperDescription = res.get_page_quiz.text.description ;}
          }
        }).catch((err)=> {
            oltConsole.log(err);
 
        });
      },
      // 整理資料
      constructorExam(){
        this.currExam = undefined;
        
        this.currIndex = -1;

        // 紀錄答案
        this.recordExamItem = new Map();
        this.recordEiidArray = new Map();

        if (this.exam != undefined && this.exam.length > 0) {
          this.examPaperType = this.exam[0].eptid;
          this.eplid = this.exam[0].eplid;
          this.huepid = this.exam[0].huepid;
        }
      },
      InitExam(){
        this.isCheckAnswer = false;

        this.isShowConcept = false;

        this.isShowAnswer = false;

        this.isCanNext = false;

        this.isGetAudioToken = false;


        if (this.isExampleExam) {
          this.btnNextName = "進入測驗"; 
          this.isCanNext = true;
        } else if (this.examPaperType == this.examPaperTypeQuiz || this.examPaperType == this.examPaperTypeListening){
          this.btnNextName = "確認送出"; 
        } else {
          this.btnNextName = "下一題"; 
        } 

        if (!this.isExampleExam) {
          // 假如不是範例題，要清除考卷說明
          this.examPaperDescription = "";
        }


        this.recordExamItem.clear();

        this.recordEiidArray.clear();
      },
      // 隨機出題
      RandomExam() {
        if (this.exam != undefined && this.exam.length > 0) {
          // 假如是聽力測驗，第一題要使用範例題目
          // 選項無法被點擊
          // 自動播放音檔，播放完畢正確答案自動出現
          // 過程中可以直接進入測驗
          if (this.examPaperType == this.examPaperTypeListening && this.isExampleExam) {
            this.currExam = this.exam[0].example_item[0];
          } 
          else {
            var index = Math.floor(Math.random() * Math.floor(this.exam.length));
            if (index < this.exam.length) {
              this.currExam = this.exam[index];
              this.currIndex = index;
            }
          }
        }
      },
      // 更新題目
      UpdateExam() {
        if (this.currExam != undefined) {
          if (this.currExam.exam_list != undefined && this.currExam.exam_list.length > 0) {
            this.mExamLength = this.currExam.exam_list.length;
          } 
          
          // 假如設定題數只有一題, 吃 exam 的 esid
          if (this.currExam.exam_mode == 1) {
              this.currExam.exam_style = (this.currExam.exam_list != undefined && this.currExam.exam_list.length > 0 && this.currExam.exam_list[0].exam != undefined && this.currExam.exam_list[0].exam.esid != undefined) ? this.currExam.exam_list[0].exam.esid : '';
          } 
          // 反之, 吃 exam_group 的 esid
          else {
              this.currExam.exam_style = (this.currExam.exam_group != undefined && this.currExam.exam_group.esid != undefined) ? this.currExam.exam_group.esid : '';
          }

          // Vue Exam Item 狀態清單
          this.selectExamItem = [];
          for (var i = 0; i < this.currExam.exam_list.length; i++) {
            this.selectExamItem[i] = [];
            for (var j = 0; j < this.currExam.exam_list[i].item.length; j++) {
              let temp = {
                id: this.currExam.exam_list[i].item[j].eiid,
                ok: false,
                fail: false,
                isCheck: false,
              };
              this.selectExamItem[i][j] = temp;
            }          
          }

          // 是否為收藏
          if (this.currExam.exam_group.collect_switch == "Y") {
            this.isCollect = true;
          } else {
            this.isCollect = false;
          }

          this.isAutoPlayAduio = true;

          this.examItemStyle = "text";
          switch (this.currExam.exam_style) {
              case "G1":
                  this.G1(this.currExam);
                  break;
              case "G2":
                  this.G2(this.currExam);
                  break;
              case "G3":
                  this.G3(this.currExam);
                  break;
              case "G4":
                  this.G4(this.currExam);
                  break;
              case "G5":
                  this.G5(this.currExam);
                  break;
              case "P1":
                  this.P1(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "P2":
                  this.P2(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "P3":
                  this.P3(this.currExam);
                  this.isAutoPlayAduio = false;
                  this.mExamLength = 1;
                  break;
              case "P4":
                  this.P4(this.currExam);
                  this.mExamLength = 1;
                  this.examItemStyle = "image";
                  break;
              case "P5":
                  this.P5(this.currExam);
                  this.isAutoPlayAduio = false;
                  this.mExamLength = 1;
                  this.examItemStyle = "image";
                  break;
              case "P6":
                  this.P6(this.currExam);
                  this.isAutoPlayAduio = false;
                  this.mExamLength = 1;
                  this.examItemStyle = "image";
                  break;
              case "Q1":
                  this.Q1(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "Q2":
                  this.Q2(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "Q3":
                  this.Q3(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "Q4":
                  this.Q4(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "Q5":
                  this.Q5(this.currExam);
                  this.mExamLength = 1;
                  break;
              case "Q6":
                  this.Q6(this.currExam);
                  this.mExamLength = 1;
                  break;
              default:
                  isErrorExam = 'Y'
                  break;
          }
        }

        if (this.isExampleExam) {
          this.playExampleAudio();
        }
      },

      // 按鍵監聽 - 選項
      OnItemClick(eiid, eid, exam_type) {
        if (this.isCheckAnswer) {
          return false;
        }

        var style = this.currExam.exam_style;
        let isSelect = 'Y';

        // 範例題不能被點選
        if (this.currExam.exam_group.is_sample != undefined && this.currExam.exam_group.is_sample == 'Y') {
          return;
        }
        
        // 選取特效 - 單選
        if (exam_type == this.SingleExamItem) {
          if (this.recordExamItem.size > 0) {
            // 假如點選同一個選項，必須取消選取
            if (this.recordExamItem.has(eiid)) {
              this.recordExamItem.delete(eiid);
              this.changeExamItemStatus(eiid, "delete");
              isSelect = 'N';
            }
            // 假如點選不同的選項，必須先判斷是否為同一小題
            // 1. 同一小題, 要取消同一小題的其他選項
            // 2. 不同小題, 不用理會直接變更為選取狀態
            else {
              this.recordExamItem.forEach((value_eid, key_eiid) => {
                if (value_eid == eid) {
                  this.recordExamItem.delete(key_eiid);
                  this.changeExamItemStatus(key_eiid, "delete");
                }
              });
              this.recordExamItem.set(eiid, eid);
              this.changeExamItemStatus(eiid, "added");
              isSelect = 'Y';
            }
          } else {
            this.recordExamItem.set(eiid, eid);
            this.changeExamItemStatus(eiid, "added");
            isSelect = 'Y';
          }
        } 
        // 選取特效 - 複選
        else if (exam_type == this.MultipleExamItem) {
            if (this.recordExamItem.has(eiid)) {
                this.recordExamItem.delete(eiid);
                this.changeExamItemStatus(eiid, "delete");
            } else {
                this.recordExamItem.set(eiid, eid);
                this.changeExamItemStatus(eiid, "added");
            }
            // 確認暫存答案陣列中是否還有當前題目的作答紀錄
            // 假如有的話就要將 isSelect設置成為 Y, 反之則設置成為 N 
            isSelect = 'N';
            this.recordExamItem.forEach(function(mEid, eiid) {
                if (mEid == eid) {
                    isSelect = 'Y';
                }
            })
        }


        // 圖片式選項
        if (this.currExam.exam_style == "P4" || this.currExam.exam_style == "P5" || this.currExam.exam_style == "P6") {
          // 考卷為等級測驗 - 下一步的行為 - 直接顯示答案
          if (this.examPaperType == this.examPaperTypeLevel) {
            this.levelExamNext("image", eiid, eid);
          } 
          // 考卷為其他 - 下一步的行為 - 紀錄是否作答完所有題目，是否可點選送出
          else {
            this.otherExamNext(eid, isSelect);
          }
        }
        // 文字式選項
        else {
          // 考卷為等級測驗 - 下一步的行為 - 直接顯示答案
          if (this.examPaperType == this.examPaperTypeLevel) {
            this.levelExamNext("text", eiid, eid);
          } 
          // 考卷為其他 - 下一步的行為 - 紀錄是否作答完所有題目，是否可點選送出
          else {
            this.otherExamNext(eid, isSelect);
          }
        }
      },

      // 題目各種題型
      /** 多個題目, emid = 2
       * Exam Group - 音檔、文字
       * Exam - X
       * Exam Item - 文字
       * Exam Concept - X
       */
      G1(examList) {
          if (examList == undefined) return;

          // Exam Group 文字    
          this.examGroupName = examList.exam_group.name;

          // Exam Group 圖片
          this.examGroupImage = "";

          // Exam Group 音檔
          this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
          this.startPlayVoice();

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
      },

      /** 多個題目, emid = 2
       * Exam Group - 音檔、音檔文字
       * Exam - X
       * Exam Item - 文字
       * Exam Concept - X
       */
      G2(examList) {
          if (examList == undefined) return;

          // Exam Group 文字    
          this.examGroupName = examList.exam_group.name;

          // Exam Group 圖片
          this.examGroupImage = "";

          // Exam Group 音檔
          this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
          this.startPlayVoice();

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
      },

      /**
       * Exam Group - 音檔
       * Exam - X
       * Exam Item - 文字
       * Exam Concept - X
       */
      G3(examList) {
          if (examList == undefined) return;

          // Exam Group 文字    
          this.examGroupName = "";

          // Exam Group 圖片
          this.examGroupImage = "";

          // Exam Group 音檔
          this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
          this.startPlayVoice();

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
      },

      /** 圖片、音檔*/
      G4(examList) {
          if (examList == undefined) return;

          // Exam Group 文字    
          this.examGroupName = "";

          // Exam Group 圖片
          this.examGroupImage = examList.exam_group.egid_image_url;

          // Exam Group 音檔
          this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
          this.startPlayVoice();

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
      },

      /** 圖片、音檔、文字 */
      G5(examList) {
          if (examList == undefined) return;
          
          // Exam Group 文字    
          this.examGroupName = examList.exam_group.name;

          // Exam Group 圖片
          this.examGroupImage = examList.exam_group.egid_image_url;

          // Exam Group 音檔
          this.audioUrlM3u8 = examList.exam_group.audio_url_m3u8;
          this.startPlayVoice();

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
      },

      /** 單個題目, emid = 1
       * Exam Group - 圖片、音檔、文字
       * Exam - X
       * Exam Item - 文字
       * Exam Concept - O
       */
      P1(examList) {
        if (examList == undefined) return;
        
        if (examList.exam_list.length > 0) {

          // Exam Group 文字    
          this.examGroupName = examList.exam_list[0].exam['name'];

          // Exam Group 圖片
          this.examGroupImage = examList.exam_list[0].exam['image_url'];

          // Exam Group 音檔
          this.audioUrlM3u8 = examList.exam_list[0].exam['audio_url_m3u8'];
          this.startPlayVoice();

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
        }
      },

      /**
       * Exam Group - 圖片、音檔
       * Exam - X
       * Exam Item - 文字
       * Exam Concept - O
       */
      P2(examList) {
        if (examList == undefined) return;
        if (examList.exam_list.length > 0) {

          // Exam Group 文字    
          this.examGroupName = "";

          // Exam Group 圖片
          this.examGroupImage = examList.exam_list[0].exam['image_url'];

          // Exam Group 音檔
          this.audioUrlM3u8 = examList.exam_list[0].exam['audio_url_m3u8'];
          this.startPlayVoice();

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
        }
      },

      /**
       * Exam Group - 圖片、文字
       * Exam - X
       * Exam Item - 文字
       * Exam Concept - O
       */
      P3(examList) {
        if (examList == undefined) return;

        if (examList.exam_list.length > 0) {

          // Exam Group 文字    
          this.examGroupName = examList.exam_list[0].exam['name'];

          // Exam Group 圖片
          this.examGroupImage = examList.exam_list[0].exam['image_url'];

          // Exam Group 音檔
          this.audioUrlM3u8 = "";

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
        }
      },

      /**
       * Exam Group - 音檔
       * Exam - X
       * Exam Item - 圖片
       * Exam Concept - O
       */
      P4(examList) { 
        if (examList == undefined) return;

        if (examList.exam_list.length > 0) {


          // Exam Group 文字    
          this.examGroupName = '';

          // Exam Group 圖片
          this.examGroupImage = '';

          // Exam Group 音檔
          this.audioUrlM3u8 = examList.exam_list[0].exam['audio_url_m3u8'];
          this.startPlayVoice();

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
        }
      },

      /**
       * Exam Group - 文字
       * Exam - 文字
       * Exam Item - 圖片
       * Exam Concept - O
       */
      P5(examList) {
        if (examList == undefined) return;

        if (examList.exam_list.length > 0) {

          // Exam Group 文字    
          this.examGroupName = examList.exam_list[0].exam['name'];

          // Exam Group 圖片
          this.examGroupImage = '';

          // Exam Group 音檔
          this.audioUrlM3u8 = '';

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
        }
      },

      /**
       * Exam Group - 文字
       * Exam - 文字
       * Exam Item - 圖片
       * Exam Concept - O
       */
      P6(examList) {
        if (examList == undefined) return;

        if (examList.exam_list.length > 0) {

          // Exam Group 文字    
          this.examGroupName = examList.exam_list[0].exam['name'];

          // Exam Group 圖片
          this.examGroupImage = '';

          // Exam Group 音檔
          this.audioUrlM3u8 = examList.exam_list[0].exam['audio_url_m3u8'];
          this.startPlayVoice();

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
        }
      },

      /**
       * Exam Group - 圖片、文字
       * Exam - 文字
       * Exam Item - 文字
       * Exam Concept - X
       */
      Q1(examList) {
        if (examList == undefined) return;

        if (examList.exam_list.length > 0) {

          // Exam Group 文字    
          this.examGroupName = examList.exam_list[0].exam['name'];

          // Exam Group 圖片
          this.examGroupImage = '';

          // Exam Group 音檔
          this.audioUrlM3u8 = examList.exam_list[0].exam['audio_url_m3u8'];
          this.startPlayVoice();

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
        }
      },

      /**
       * Exam Group - 音檔、文字
       * Exam - 無
       * Exam Item - 文字
       * Exam Concept - X
       */
      Q2(examList) {
        if (examList == undefined) return;

        if (examList.exam_list.length > 0) {

          // Exam Group 文字    
          this.examGroupName = examList.exam_list[0].exam['name'];

          // Exam Group 圖片
          this.examGroupImage = '';

          // Exam Group 音檔
          this.audioUrlM3u8 = examList.exam_list[0].exam['audio_url_m3u8'];
          this.startPlayVoice();

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
        }
      },

      /**
       * Exam Group - 音檔、文字
       * Exam - 無
       * Exam Item - 文字
       * Exam Concept - O
       */
      Q3(examList) {
        if (examList == undefined) return;

        if (examList.exam_list.length > 0) {

          // Exam Group 文字    
          this.examGroupName = examList.exam_list[0].exam['name'];

          // Exam Group 圖片
          this.examGroupImage = '';

          // Exam Group 音檔
          this.audioUrlM3u8 = examList.exam_list[0].exam['audio_url_m3u8'];
          this.startPlayVoice();

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
        }
      },

      /**
       * Exam Group - 音檔
       * Exam - X
       * Exam Item - 文字
       * Exam Concept - O
       */
      Q4(examList) {
        if (examList == undefined) return;

        if (examList.exam_list.length > 0) {

          // Exam Group 文字    
          this.examGroupName = '';

          // Exam Group 圖片
          this.examGroupImage = '';

          // Exam Group 音檔
          this.audioUrlM3u8 = examList.exam_list[0].exam['audio_url_m3u8'];
          this.startPlayVoice();

          // 取得總題數
          this.mExamLength = examList.exam_list.length;
        }
      },

      /**
       * Exam Group - X
       * Exam - X
       * Exam Item - 文字
       * Exam Concept - O
       */
      Q5(examList) {
          if (examList == undefined) return;

          if (examList.exam_list.length > 0) {


            // Exam Group 文字    
            this.examGroupName = '';

            // Exam Group 圖片
            this.examGroupImage = '';

            // Exam Group 音檔
            this.audioUrlM3u8 = '';

            // 取得總題數
            this.mExamLength = examList.exam_list.length;
          }
      },

      /**
       * Exam Group - 文字(填空))
       * Exam - X
       * Exam Item - 文字
       * Exam Concept - O
       */
      Q6(examList) {
        if (examList == undefined) return;

        // Exam Group 文字    
        this.examGroupName = examList.exam_list[0].exam['name'];

        // Exam Group 圖片
        this.examGroupImage = '';

        // Exam Group 音檔
        this.audioUrlM3u8 = '';

        // 取得總題數
        this.mExamLength = examList.exam_list.length;
      },
      // 選項
      IDontKnowTimer(){
        var tempExam = this.exam;
        var initExam = this.InitExam;
        var randomExam = this.RandomExam;
        var updateExam = this.UpdateExam;
        var openComplete = this.openComplete;

        this.exam.splice(this.currIndex, 1);

        this.form["exam"] = {
          "egid": this.currExam.exam_group.egid,
          "eid": this.currExam.exam_list[0].exam.eid,
          "result_type": "id",
          "eiid": undefined
        };
        
        this.postExamAnswer(function(){
          setTimeout(function() {
            if (tempExam != undefined && tempExam.length > 0) {
                initExam();
                randomExam();
                updateExam();
            } else {
              openComplete();
            }
          }, 500);
        });
      },
      // 播放音檔
      loadCorrectAudio(){
        this.$JsBridge.callHandler('loadCorrectAudioVue',JSON.stringify({backUrl:"Quiz", type:""}), this.getExamAudioCallback);
      },

      loadErrorAudio(){
        this.$JsBridge.callHandler('loadErrorAudioVue',JSON.stringify({backUrl:"Quiz", type:""}), this.getExamAudioCallback);
      },

      stopPlayVoice(){
        this.$refs.videoPlayer.player.pause();
        this.$refs.videoPlayer.player.currentTime(0);
        this.isPlayAudio = false;
      },

      // 開始播放音檔
      startPlayVoice() {
        if (this.isPlayAudio) {
          this.stopPlayVoice();
        } else if (this.isGetAudioToken) {
          this.$refs.videoPlayer.player.play();
          this.isPlayAudio = true;
        } else {
          if (this.audioUrlM3u8 == undefined) {
            return console.log("this.audioUrlM3u8 is undefined ================ ");
          }            
          this.apiName = "video_get_token";
          oAC.getAllRecord(oAC.getApiParam(this), {src: this.audioUrlM3u8}).then(res =>{
            if (res.get_video_token != undefined && res.get_video_token.data != undefined && res.get_video_token.data.token != undefined) {
              var json = {
                  type: "application/x-mpegURL",
                  src : `${this.audioUrlM3u8}?token=${res.get_video_token.data.token}`,
              };
              this.playerOptions.sources = [];
              this.playerOptions.sources.push(json);
              this.isGetAudioToken = true;
              if(this.$JsBridge.isiOS){
                this.$refs.videoPlayer.muted = true;
              }

            }
          }).catch((err)=> {
            oltConsole.log(err);
          });            
        }

      },
  
      /**名稱: 答題音效
       * 描述: 答對或答錯要播放音效 , 在進入下一題
       * 用於: 等級測驗
       */
      getExamAudioCallback() {
        if (this.examPaperType == this.examPaperTypeLevel) {
          if (this.exam != undefined && this.exam.length > 0) {
            this.InitExam();
            this.RandomExam();
            this.UpdateExam();
          } else {
            this.openComplete();
          }
        }
      },
      playExampleAudio(){
        if (this.isPlayAudio) {
          this.stopPlayVoice();
        } else if (this.isGetAudioToken) {
          this.$refs.videoPlayer.player.play();
          this.isPlayAudio = true;
        } else {
          this.apiName = "video_get_token";
          oAC.getAllRecord(oAC.getApiParam(this), {src: this.audioUrlM3u8}).then(res =>{
            if (res.get_video_token != undefined && res.get_video_token.data != undefined && res.get_video_token.data.token != undefined) {
              var json = {
                  type: "application/x-mpegURL",
                  src : `${this.audioUrlM3u8}?token=${res.get_video_token.data.token}`,
              };
              this.playerOptions.sources = [];
              this.playerOptions.sources.push(json);
              this.isGetAudioToken = true;
              if(this.$JsBridge.isiOS){
                this.$refs.videoPlayer.muted = true;
              }
            }
          }).catch((err)=> {
            oltConsole.log(err);
          });              
        }
      },
      playExampleAudioCallback(){
        this.isPlayAudio = false;
        this.changeExampleExamItemStatus();
      },
      // 紀錄作答
      postExamAnswer(callback) {
        this.apiName = "page_quiz_answer";
        oAC.getAllRecord(oAC.getApiParam(this), this.form ).then(callback).catch((err)=> {
            oltConsole.log(err);
        });
      },
      // 紀錄作答
      postExamAnswer2(data) {
        this.form = {
          eplid: this.eplid,
          huepid: this.huepid,
          uid: this.$GlobalData.uid,
          exam: data,
        };

        this.apiName="page_quiz_answer";
        oAC.postRecord(oAC.getApiParam(this)).then(()=> {
          
        }).catch((err)=> {
          oltConsole.log(err);
        });  
      },
      // 完成測驗
      openComplete(){
        if (this.$route.params.back != undefined && this.$route.params.back == "CollectExamGroup") {
          this.$router.push({
            name: this.$route.params.back,
            params:this.$route.params
          });
        } 
        else {
          // 原本是有完成畫面，後來需求拿掉
          this.apiName="page_quiz_complete";

          var object = {
            uid: this.$GlobalData.uid, 
            eplid: this.eplid, 
            huepid: this.huepid, 
            lsid: this.lsid, 
            tryError: this.tryError
          };

          oAC.getAllRecord(oAC.getApiParam(this), object).then(res => {

            if (res.complete_5 != undefined && res.complete_5 == "Y" && res.first != undefined && res.first == "Y") {
              this.$JsBridge.callHandler('loadLocalDataVue', JSON.stringify( {key: "isTaskCompleteFirst"}), this.loadTaskFirstCallback);
            } else {
              this.$router.push({
                name: "TaskList",
                params:{
                  lsid: this.lsid,
                  taskBack: this.taskBack
                }
              });
            }
          }).catch((err)=> {
            oltConsole.log(err);
          });
        }
      },
      loadTaskFirstCallback(data){
          if(data != "" && data != "N"){
              try{
                  var json = JSON.parse(data);
                  json[`lsid_${this.lsid}`] = "Y";
                  var saveJson = {
                      key: "isTaskCompleteFirst",
                      object: JSON.stringify(json),
                  };
                  this.$JsBridge.callHandler('saveLocalDataVue', JSON.stringify( saveJson ), this.saveTaskFirstCallback);
              }catch(err) {
                  console.log(err);
              }
          }else{
                  var json = {};
                  json[`lsid_${this.lsid}`] = "Y";
                  var saveJson = {
                      key: "isTaskCompleteFirst",
                      object: JSON.stringify(json),
                  };
                  this.$JsBridge.callHandler('saveLocalDataVue', JSON.stringify( saveJson ), this.saveTaskFirstCallback);
          }
      },
      saveTaskFirstCallback(){
        this.$router.push({
          name: "TaskList",
          params:{
            lsid: this.lsid,
            taskBack: this.taskBack
          }
        });
      },
      // 按鈕-下一題
      OnNextClick(){
        this.stopPlayVoice();
        // 範例題目可以直接進入下一題
        if (this.isExampleExam) {
          this.isExampleExam = false;
          if (this.exam != undefined && this.exam.length > 0) {
            this.InitExam();
            this.RandomExam();
            this.UpdateExam();
          } else {
            this.openComplete();
          }
        } 
        // 其他題目要顯示答案和解析，再按一次才是進入下一題
        else {
          // 還沒顯示答案要顯示答案
          if (!this.isShowAnswer) {
            this.showVueAnswer();
            this.isCheckAnswer = true;
            if (this.exam != undefined && this.exam.length > 0) {
              this.btnNextName = "下一題";
            } else {
              this.btnNextName = "完成測驗";
            }
          } 
          // 再按一次才是進入下一題
          else {
            this.isCheckAnswer = false;
            if (this.exam != undefined && this.exam.length > 0) {
              this.InitExam();
              this.RandomExam();
              this.UpdateExam();
            } else {
              this.openComplete();
            }
          }
        }
      },
      // 選取範例題選項
      changeExampleExamItemStatus(){
        if (this.selectExamItem != undefined && this.selectExamItem.length > 0) {
          for (var i = this.selectExamItem.length - 1; i >= 0; i--) {
            for (var j = this.selectExamItem[i].length - 1; j >= 0; j--) {
              if (this.selectExamItem[i][j].id == this.currExam.exam_list[i].answer[0].eiid){
                $("#ok"+this.selectExamItem[i][j].id).removeClass("hide");
                // 假如是圖片選項
                if (this.currExam.exam_style == "P4" || this.currExam.exam_style == "P5" || this.currExam.exam_style == "P6") {
                  $("#text"+this.selectExamItem[i][j].id).addClass("greentestpicborder");
                } 
                // 假如是文字選項
                else {
                  $("#text"+this.selectExamItem[i][j].id).removeClass("badge-secondary");
                  $("#text"+this.selectExamItem[i][j].id).addClass("badge-success");
                }
              }
            }
          }
        }
      },
      // 選取一般題選項
      changeExamItemStatus(eiid, type){
        if (this.selectExamItem != undefined && this.selectExamItem.length > 0) {
          for (var i = this.selectExamItem.length - 1; i >= 0; i--) {
            for (var j = this.selectExamItem[i].length - 1; j >= 0; j--) {
              if (this.selectExamItem[i][j].id == eiid){
                // 假如是圖片選項
                if (this.currExam.exam_style == "P4" || this.currExam.exam_style == "P5" || this.currExam.exam_style == "P6") {
                  if (type == "delete") {
                    $("#text"+this.selectExamItem[i][j].id).removeClass("greentestpicborder");
                  } else {
                    $("#text"+this.selectExamItem[i][j].id).addClass("greentestpicborder");
                  }
                } 
                // 假如是文字選項
                else {
                  if (type == "delete") {
                    $("#text"+this.selectExamItem[i][j].id).removeClass("greentestpicborder");
                  } else {
                    $("#text"+this.selectExamItem[i][j].id).addClass("greentestpicborder");
                  }
                }
              }
            }
          }
        }
      },
      // 顯示答案
      showVueAnswer(){
        let playCorrectAudio = false;
        this.isShowAnswer = true;
        this.isShowConcept = true;
        let tempArr = this.currExam;

        // 圖片選項
        if (this.currExam.exam_style == "P4" || this.currExam.exam_style == "P5" || this.currExam.exam_style == "P6") {
          // 多少個小題
          for (var i = 0; i < this.currExam.exam_list.length; i++) {
            if (this.currExam.exam_list[i].answer != undefined && this.currExam.exam_list[i].answer.length > 0) {
              // 多少個答案
              for (var j = 0; j < this.currExam.exam_list[i].answer.length; j++) {
                // 加上正確的圖示
                if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                  $("#ok"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("hide");
                  // 綠色漸層
                  let tempImage = $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).css("background-image");
                  let imageSuccess = 'background-image: linear-gradient(to bottom, rgba(96, 255, 231, 0.4), rgba(96, 255, 231, 0.4)), '+tempImage; // Success
                  for (var k = 0; k < tempArr.exam_list.length; k++) {
                    for (var l = 0; l < tempArr.exam_list[k].item.length; l++) {
                      if (tempArr.exam_list[k].item[l].eiid == this.currExam.exam_list[i].answer[j]["eiid"]) {
                        tempArr.exam_list[k].item[l].image_url = imageSuccess;
                      }
                    }
                  }

                  // 綠色邊框
                  $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).addClass("greentestpicborder");
                  playCorrectAudio = true;
                }
                else {
                  // 綠色漸層
                  let tempImage = $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).css("background-image");
                  let imageSuccess = 'background-image: linear-gradient(to bottom, rgba(96, 255, 231, 0.4), rgba(96, 255, 231, 0.4)), '+tempImage; // Success

                  for (var k = 0; k < tempArr.exam_list.length; k++) {
                    for (var l = 0; l < tempArr.exam_list[k].item.length; l++) {
                      if (tempArr.exam_list[k].item[l].eiid == this.currExam.exam_list[i].answer[j]["eiid"]) {
                        tempArr.exam_list[k].item[l].image_url = imageSuccess;
                      }
                    }
                  }

                  // 綠色邊框
                  $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).addClass("greentestpicborder");
                }

                // 紀錄答案, 並刪除目前紀錄在 MAP 裡面的資料                    
                if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                  this.recordEiidArray.set(this.currExam.exam_list[i].answer[j]["eiid"], this.currExam.exam_list[i].answer[j]["eid"]);
                  this.recordExamItem.delete(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]));
                }
              }
            }
          }
          // 假如 MAP 裡面還有剩餘資料， 必須將它標示為錯誤的
          if (this.recordExamItem.size > 0) {
            this.recordExamItem.forEach((value_eid, key_eiid, items) => {
              // 紀錄答案
              this.recordEiidArray.set(key_eiid, value_eid);
              $("#fail"+key_eiid).removeClass("hide");
              $("#text"+key_eiid).removeClass("greentestpicborder");
              // 紅色漸層
              let tempImage = $("#text"+key_eiid).css("background-image");
              let imageError = 'background-image: linear-gradient(to bottom, rgba(255, 158, 158, 0.4), rgba(255, 158, 158, 0.4)), '+tempImage; // Error

              for (var i = 0; i < tempArr.exam_list.length; i++) {
                for (var j = 0; j < tempArr.exam_list[i].item.length; j++) {
                  if (tempArr.exam_list[i].item[j].eiid == key_eiid) {
                    tempArr.exam_list[i].item[j].image_url = imageError;
                  }
                }
              }

              // 刪除選取
              $("#text"+key_eiid).removeClass("greentestpicborder");

              // 紅色邊框
              $("#text"+key_eiid).addClass("redtestpicborder");
              playCorrectAudio = false;
            });
          }
        }
        // 文字選項
        else {
          // 多少個小題
          for (var i = 0; i < this.currExam.exam_list.length; i++) {
            if (this.currExam.exam_list[i].answer != undefined && this.currExam.exam_list[i].answer.length > 0) {
              // 多少個答案
              for (var j = 0; j < this.currExam.exam_list[i].answer.length; j++) {
                // 加上正確的圖示
                if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                  $("#ok"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("hide");
                  // 刪除選取
                  $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("greentestpicborder");
                  $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("badge-secondary");
                  $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).addClass("badge-success");
                  playCorrectAudio = true;
                } else {
                  $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("greentestpicborder");
                  $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).removeClass("badge-secondary");
                  $("#text"+this.currExam.exam_list[i].answer[j]["eiid"]).addClass("badge-success");
                }
                // 紀錄答案, 並刪除目前紀錄在 MAP 裡面的資料                    
                if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                  this.recordEiidArray.set(this.currExam.exam_list[i].answer[j]["eiid"], this.currExam.exam_list[i].answer[j]["eid"]);
                  this.recordExamItem.delete(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]));
                }
              }                      
            }
          }
          // 假如 MAP 裡面還有剩餘資料， 必須將它標示為錯誤的
          if (this.recordExamItem.size > 0) {
            this.recordExamItem.forEach((value_eid, key_eiid, items) => {
              // 紀錄答案
              this.recordEiidArray.set(key_eiid, value_eid);
                $("#fail"+key_eiid).removeClass("hide");
                $("#text"+key_eiid).removeClass("badge-secondary");
                $("#text"+key_eiid).removeClass("badge-success");
                // 刪除選取
                $("#text"+key_eiid).removeClass("greentestpicborder");
                $("#text"+key_eiid).addClass("badge-danger");
                playCorrectAudio = false;
            });
          }
        }
        this.currExam = tempArr;
        this.saveAnswer();

        // 刪除出現的題目
        this.exam.splice(this.currIndex, 1);

        if (playCorrectAudio) {
          this.loadCorrectAudio();
        } else {
          this.loadErrorAudio();
        }
      },
      levelExamNext(type, eiid, eid){
        // 停止計時器
        // this.clearInterval(this.timer);

        // 停止播放音檔
        this.stopPlayVoice();

        let playCorrectAudio = false;

        if (type == "image") {
          // 選了我不知道的選項
          if (eiid == undefined) {
            this.IDontKnowTimer();
          }
          // 選擇其他選項
          else {
            // 多少個小題
            for (var i = 0; i < this.currExam.exam_list.length; i++) {
              if (this.currExam.exam_list[i].answer != undefined && this.currExam.exam_list[i].answer.length > 0) {
                // 多少個答案
                for (var j = 0; j < this.currExam.exam_list[i].answer.length; j++) {
                  // 加上正確的圖示
                  if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                    for (var k = 0; k < this.selectExamItem.length; k++) {
                      if (this.selectExamItem[k].id == this.currExam.exam_list[i].answer[j]["eiid"]) {
                        $("#ok"+this.selectExamItem[k].id).removeClass("hide");
                        $("#text"+this.selectExamItem[k].id).addClass("greentestpicborder");
                        // var tempImage = (exam.exam_list[0].item[k].image_url != undefined) ? this.selectExamItem[k].image_url : "assets/img/automotive/g-grey.jpg";
                        // $("#text"+this.selectExamItem[k].id).attr("style", 'background-image: linear-gradient(to bottom, rgba(96, 255, 231, 0.4), rgba(96, 255, 231, 0.4)), url(' + tempImage + '); ') 
                        playCorrectAudio = true;
                      }
                    }
                  }

                  // 紀錄答案, 並刪除目前紀錄在 MAP 裡面的資料                    
                  if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                    this.recordEiidArray.set(this.currExam.exam_list[i].answer[j]["eiid"], this.currExam.exam_list[i].answer[j]["eid"]);
                    this.recordExamItem.delete(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]));
                  }
                }
              }
            }

            // 假如 MAP 裡面還有剩餘資料， 必須將它標示為錯誤的
            if (this.recordExamItem.size > 0) {
              var tempArr = this.selectExamItem;
              this.recordExamItem.forEach((value_eid, key_eiid, items) => {
                // 紀錄答案
                this.recordEiidArray.set(key_eiid, value_eid);
                exam = this.currExam;
                if (tempArr.length > 0) {
                  for (var i = 0; i < tempArr.length; i++) {
                    if (tempArr[i].id == key_eiid) {
                      $("#fail"+key_eiid).removeClass("hide");
                      $("#text"+key_eiid).addClass("redtestpicborder");
                      playCorrectAudio = false;
                    }
                  }    
                }
              })
            }
          } 
        } 
        else if (type == "text") {
          // 選了我不知道的選項
          if (eiid == undefined) {
            this.IDontKnowTimer();
          }
          // 選擇其他選項
          else {
            // 多少個小題
            for (var i = 0; i < this.currExam.exam_list.length; i++) {
              if (this.currExam.exam_list[i].answer != undefined && this.currExam.exam_list[i].answer.length > 0) {
                // 多少個答案
                for (var j = 0; j < this.currExam.exam_list[i].answer.length; j++) {
                  // 加上正確的圖示
                  if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                    for (var k = 0; k < this.selectExamItem.length; k++) {
                      if (this.selectExamItem[k].id == this.currExam.exam_list[i].answer[j]["eiid"]) {
                        $("#ok"+this.selectExamItem[k].id).removeClass("hide");
                        $("#text"+this.selectExamItem[k].id).removeClass("badge-secondary");
                        $("#text"+this.selectExamItem[k].id).addClass("badge-success");
                      }
                    }
                    playCorrectAudio = true;
                  }

                  // 紀錄答案, 並刪除目前紀錄在 MAP 裡面的資料                    
                  if (this.recordExamItem.has(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]))) {
                    this.recordEiidArray.set(this.currExam.exam_list[i].answer[j]["eiid"], this.currExam.exam_list[i].answer[j]["eid"]);
                    this.recordExamItem.delete(parseInt(this.currExam.exam_list[i].answer[j]["eiid"]));
                  }
                }                      
              }
            }

            // 假如 MAP 裡面還有剩餘資料， 必須將它標示為錯誤的
            if (this.recordExamItem.size > 0) {
              var tempArr = this.selectExamItem;
              this.recordExamItem.forEach((value_eid, key_eiid, items) => {
                // 紀錄答案
                this.recordEiidArray.set(key_eiid, value_eid);
                if (tempArr.length > 0) {
                  for (var i = 0; i < tempArr.length; i++) {
                    if (tempArr[i].id == key_eiid) {
                      $("#fail"+key_eiid).removeClass("hide");
                      $("#text"+key_eiid).removeClass("badge-secondary");
                      $("#text"+key_eiid).addClass("badge-danger");
                      playCorrectAudio = false;
                    }
                  }    
                }
              });
            }
          }
        }

        this.examNextTwo(playCorrectAudio);
      },
      otherExamNext(eid, isSelect){
        let countExam = 0;
        // 顯示/隱藏下一題按鈕
        // 不能以數量來做為顯示的依據，因為題組的題目是複選題時，會超過
        // 正確做法是 -> 先確認每個題目是否有作答, 假如都有作答就給過 
        // mExamLength : 總題數
        $(".exam").each(function(a, b) {
            if (b.id == eid) {
                $("#" + b.id).attr("data", isSelect);
            }
            if ($("#" + b.id).attr("data") == 'Y') {
                countExam++;
            }
        });

        if (countExam == this.mExamLength) {
          this.isCanNext = true;
        } else {
          this.isCanNext = false;
        }
      },
      examNextTwo(playCorrectAudio){
        this.saveAnswer();

        // 刪除出現的題目
        this.exam.splice(this.currIndex, 1);
        if (playCorrectAudio) {
          this.loadCorrectAudio();
        } else {
          this.loadErrorAudio();
        }
      },
      saveAnswer(){
        // 儲存作答紀錄
        if (this.recordEiidArray.size > 0) {
          var tempExam = [];
          var postExamAnswer2 = this.postExamAnswer2;
          var tempExamArr = [];
          this.form["uid"] = this.$GlobalData.uid;
          this.recordEiidArray.forEach((value_eid, key_eiid, item) => {
            tempExam = {
              "egid": JSON.stringify(this.currExam.exam_group.egid),
              "eid": JSON.stringify(value_eid),
              "eiid": JSON.stringify(key_eiid),
              "result_type": "id"
            };
            tempExamArr.push(tempExam);
          });

          postExamAnswer2(tempExamArr);
        }
      },
      // listen event
      onPlayerPlay(player) {
        // console.log(`onPlayerPlay ==> ${player}`);
      },
      onPlayerPause(player) {
        // console.log(`onPlayerPause ==> ${player}`);
      },
      playerStateChanged(playerCurrentState) {
        // console.log(`playerStateChanged ==> ${playerCurrentState}`);
      },
      onPlayerEnded(player) {
        // console.log(`onPlayerEnded ==> ${player}`);
        this.isPlayAudio = false;
        if (this.isExampleExam) {
          this.changeExampleExamItemStatus();
        }
      },
      onPlayerPlaying(player) {
        // console.log(`onPlayerPlaying ==> ${player}`);
      },
      onPlayerWaiting(player) {
        // console.log(`onPlayerWaiting ==> ${player}`);
      },
      onPlayerLoadeddata(player) {
        // console.log(`onPlayerLoadeddata ==> ${player}`);
      },
      onPlayerTimeupdate(player) {
        // console.log(`onPlayerTimeupdate ==> ${player}`);
      },
      onPlayerCanplay(player) {
        // console.log(`onPlayerCanplay ==> ${player}`);
        if (this.isShowHistory || this.isCheckAnswer || !this.isGetAudioToken || this.isPlayAudio) {

        } else if (this.$JsBridge.isAndroid) {
          this.onPlay();
        }
      },
      onPlayerCanplaythrough(player) {
        // console.log(`onPlayerCanplaythrough ==> ${player}`);
      },
      // player is ready
      playerReadied(player) {
        // console.log('the player is readied', player)
          // you can use it to do something...
          // player.[methods]
        if (this.$JsBridge.isiOS) {
          setTimeout(this.onPlay, 300);
        }
      },
      onPlay(){
        this.$refs.videoPlayer.player.play();
        this.isPlayAudio = true;
      },
      collectExam(){
        this.form = {
          egid: this.currExam.exam_group.egid,
          switch: this.isCollect ? "N" : "Y",
          uid: this.$GlobalData.uid
        };

        this.apiName="page_quiz_collect";
        oAC.postRecord(oAC.getApiParam(this)).then((res)=> {
          if (this.isCollect) {
            this.isCollect = false;
          } else {
            this.isCollect = true;
          }
        }).catch((err)=> {
          oltConsole.log(err);
        });
      },
      getSearch(){
        // 考卷ID
        if (this.$route.query.eplid != undefined) {
          this.eplid = this.$route.query.eplid;
        } else if (this.$route.params.eplid != undefined) {
          this.eplid = this.$route.params.eplid;
        }

        // 用戶ID
        if (this.$route.query.uid != undefined) {
          this.$GlobalData.uid = this.$route.query.uid;
        }

        // 課程ID
        if (this.$route.query.lsid != undefined) {
          this.lsid = this.$route.query.lsid;
        } else if (this.$route.params.lsid != undefined) {
          this.lsid = this.$route.params.lsid;
        } 

        // 錯題重測
        if (this.$route.query.tryError != undefined) {
          this.tryError = this.$route.query.tryError;
        } else if (this.$route.params.tryError != undefined) {
          this.tryError = this.$route.params.tryError;
        } 

        // Task 返回畫面
        if (this.$route.query.taskBack != undefined) {
          this.taskBack = this.$route.query.taskBack;
        } else if (this.$route.params.taskBack != undefined) {
          this.taskBack = this.$route.params.taskBack;
        }

        if (this.$route.query.egid != undefined) {
          this.egid = this.$route.query.egid;
        } else if (this.$route.params.egid != undefined) {
          this.egid = this.$route.params.egid;
        }
      },
      showModal(target){
        $(target).modal("show");
      },
      getBackUrl(){
        if (this.$route.params.back != undefined && this.$route.params.back != "" ) {
          this.backUrl = this.$route.params.back;
        } else if (this.$route.query.back != undefined && this.$route.query.back != "" ) {
          this.backUrl = this.$route.query.back;
        } else if (this.$route.params.backUrl != undefined && this.$route.params.backUrl != "" ) {
          this.backUrl = this.$route.params.backUrl;
        } else if (this.$route.query.backUrl != undefined && this.$route.query.backUrl != "" ) {
          this.backUrl = this.$route.query.backUrl;
        }
      }
    },
    created(){
      if (this.$JsBridge.isAndroid) {
        this.setOnBack();
      }
      this.getBackUrl();

      this.getSearch();

      this.getAPI();
    },
    mounted()
    { 
		
		this.config = config;
    }

};
</script>
<style>
@import '../../../../public/assets/css/admin-customize.css';
  .addedHight{
    height: 25px;
  }
  .hide{
    display: none !important;
  }
  .examItemSuccess{
    background-image: linear-gradient(to bottom, rgba(96, 255, 231, 0.4), rgba(96, 255, 231, 0.4));
  }
  .examItemError{
    background-image: linear-gradient(to bottom, rgba(255, 158, 158, 0.4), rgba(255, 158, 158, 0.4));
  }
  .examItemImage{
    background-size:contain; 
    background-repeat: no-repeat; 
    background-position:center;
  }
  .disabled{
    color: #fff !important;
    background-color: #c9c9c9 !important;
  }

  body{
    color: #2e2e2e !important;
    font-size: 20px !important;
  }

  .badge-secondary span,
  .badge-secondary {
    color: #43494e;
  }
</style>
